import { Module } from "vuex";
import { IRootState } from "../types";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { IUserState } from "./types";

export const state: IUserState = {
  error: false,
  loading: false,
  saving: false,
  user: null,
};

const namespaced: boolean = true;

export const user: Module<IUserState, IRootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state,
};
