import { ActionTree } from "vuex";
import { IRootState } from "../types";
import { IArtistArtworkPricesState } from "./types";

export const actions: ActionTree<IArtistArtworkPricesState, IRootState> = {
  fetchData(
    { commit, rootState },
    params: {
      artistId: number;
      unit?: string;
      currency?: string;
      groupArtistId?: string;
      portfolioId?: string;
    }
  ): any {
    commit("state", "loading");
    commit("errors", []);
    const paramObject: any = {};
    if (params.unit) paramObject.unit = params.unit;
    if (params.currency) paramObject.currency = params.currency;
    if (params.groupArtistId) paramObject.group_artist_id = params.groupArtistId;
    if (params.portfolioId) paramObject.portfolio_id = params.portfolioId;
    const paramString = new URLSearchParams(paramObject).toString();
    fetch(
      `${rootState.apiEndpoint}web/v1/artists/${params.artistId}/artwork-prices.json?${paramString}`,
      rootState.fetchOptions
    )
      .then((response) => response.json())
      .then((data) => {
        const payload: any = data.data;
        commit("prices", payload);
        commit("state", payload.length > 0 ? "loaded" : "empty");
      })
      .catch((error) => {
        commit("errors", [error.message]);
        commit("state", "error");
      });
  },
};
