import { Module } from "vuex";
import { IPortfolioState } from "./types";
import { IRootState } from "../types";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";

export const state: IPortfolioState = {
  status: [],
  isFiltered: false,
  selectedUuids: [],
  portfolio: null,
  search: "",
  state: "pre-load",
  // @ts-ignore
  report: localStorage.getItem("portfolio_report") || "overview",
  // @ts-ignore
  view: localStorage.getItem("portfolio_view") || "table",
  // @ts-ignore
  prices: JSON.parse(localStorage.getItem("portfolio_prices")) || {
    key: "range",
    name: "Static Range",
  },
  isFocusFiltered: false,
};

const namespaced: boolean = true;

export const portfolio: Module<IPortfolioState, IRootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state,
};
