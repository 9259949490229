import { GetterTree } from "vuex";
import { IRootState } from "../types";
import { IMenuState } from "./types";

export const getters: GetterTree<IMenuState, IRootState> = {
  filtersVisible(state): boolean {
    return state.filtersVisible;
  },
  menuVisible(state): boolean {
    return state.menuVisible;
  },
  menuClasses(state): string {
    let classes: string = "";
    classes += state.menuVisible ? " menu-is-visible " : " menu-is-hidden ";
    classes += state.filtersVisible
      ? " filters-are-visible "
      : " filters-are-hidden ";
    return classes;
  },
  userMenuVisible(state): boolean {
    return state.userMenuVisible;
  },
};
