import { GetterTree } from "vuex";
import { IRootState } from "../types";
import { IGroupedNotifications, INotificationsState } from "./types";

export const getters: GetterTree<INotificationsState, IRootState> = {
  groupedNotifications(state): IGroupedNotifications {
    return state.groupedNotifications;
  },
  notificationCount(state): string {
    if (state.groupedNotifications.length > 0) {
      const count: number = state.groupedNotifications
        .map((g) => {
          return g.notifications.filter((n) => !n.received_at).length;
        })
        .reduce((a, b) => a + b);
      if (count >= 9000) {
        return "9k+";
      }
      if (count > 999) {
        return `${Math.round(count / 1000)}k+`;
      }
      if (count > 99) {
        return "99+";
      }
      if (count > 0) {
        return `${count}`;
      }
      return "";
    }
    return "";
  },
};
