import { Module } from "vuex";
import { IRootState } from "../types";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { IMenuState } from "./types";

export const state: IMenuState = {
  filtersVisible: false,
  menuVisible: false,
  userMenuVisible: false,
};

const namespaced: boolean = true;

export const menu: Module<IMenuState, IRootState> = {
  getters,
  mutations,
  namespaced,
  state,
};
