<script setup lang="ts">
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  
  const store = useStore();
  
  const hasUnmatchedLines = computed(
    () => store.getters["portfolio/unmatched"]?.length > 0
  );
  
  const openAddArtistOverlay = () => {
    store.commit("addModal", "AddImportSearchArtistModal");
  };
  
  const goToOther = () => {
    store.commit("portfolio/setView", "unmatched");
  };
  
  const state = computed(
    () => store.getters["portfolio/state"]
  );
</script>

<template lang="pug">
  .w-64.max-w-lg.bg-white.rounded.shadow-lg.p-4(v-if="state !== 'importing'")
    .flex.flex-col.justify-center(v-if="hasUnmatchedLines")
      h2.text-lg.flex.justify-center.items-start.p-4
        | Get Started
      | No assets have been matched. Go to the "other" tab to see all assets.
      button(
        @click='goToOther'
        class=`
          font-medium text-sm leading-snug
          flex items-center justify-center
          px-3 py-2 mr-2 mt-4
          border-2 border-solid border-gray-300
          bg-white shadow rounded
          transition duration-500 ease-in-out delay-75
          hover:shadow-lg focus:shadow-lg hover:bg-gray-100
          focus:outline-none focus:border-blue-300 focus:bg-gray-100
        `
      ) View Other Assets
    .flex.flex-col.justify-center(v-else)
      h2.text-lg.flex.justify-center.items-start.p-4
        | Get Started
      button(
        @click='openAddArtistOverlay'
        class=`
          relative
          font-medium text-sm leading-snug
          flex items-center justify-center
          px-3 py-2 mr-2
          border-2 border-solid border-gray-300
          bg-white shadow rounded
          transition duration-500 ease-in-out delay-75
          hover:shadow-lg focus:shadow-lg hover:bg-gray-100
          focus:outline-none focus:border-blue-300 focus:bg-gray-100
        `
      )
        span(
          class="motion-safe:animate-ping absolute inline-flex h-2 w-2 -top-1 -left-1 rounded-full bg-sky-400 opacity-75"
        )
        svg(
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          class="plus w-4 h-4"
        )
          path(
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          )
        span(
          class=`hidden sm:inline flex items-center`
        ) Add / Import Assets
</template>
