export interface ISizeFormatterParams {
  height: number | null;
  width: number | null;
  depth: number | null;
  unit: string | null;
  category?: "S" | "M" | "L" | null;
}

export function sizeFormatter(input: ISizeFormatterParams): string {
  let size = "";
  
  if (!input.height || !input.width || !input.unit) return size;
  
  size += `${input.height} x ${input.width}`;
  
  if (input.depth) size += ` x ${input.depth}`;
  
  size += ` ${input.unit}`;
  
  if (input.category) size += ` (${input.category})`;
  
  return size;
}