import { GetterTree } from "vuex";
import { IRootState } from "../types";
import { IAction, IActions, IInputState } from "./types";

export const getters: GetterTree<IInputState, IRootState> = {
  autoCompleteList(state): any {
    if (state.selected.length === 0) {
      const sanitizedInput: string = state.input
        .replace(/\s+/g, "")
        .replace(/(\\|\/|\?|\||\.|\*|\+|\^|\$|\[|\]|\(|\))/g, "\\$1");
      const matcher: RegExp = new RegExp(sanitizedInput, "i");
      return state.actions.filter((action: IAction): boolean => {
        const matchResult = matcher.test(action.name.replace(/\s+/g, ""));
        if (matchResult) {
          return matchResult;
        }
        if (action.alises) {
          return action.alises.some((alias: string): boolean => {
            return alias && matcher.test(alias.replace(/\s+/g, ""))
              ? true
              : false;
          });
        }
        return false;
      });
    }
    return state.autoCompleteItems;
  },
  input(state): string {
    return state.input;
  },
  isFocused(state): boolean {
    return state.isFocused;
  },
  selected(state): IActions {
    return state.selected;
  },
};
