import { ActionTree } from "vuex";
import { IRootState } from "../types";
import { IPortfoliosState } from "./types";

export const actions: ActionTree<IPortfoliosState, IRootState> = {
  fetchData({ commit, rootState }): void {
    commit("setState", "loading");

    fetch(`${rootState.apiEndpoint}web/v1/groups.json`, rootState.fetchOptions)
      .then((response) => response.json())
      .then((data) => {
        commit("setState", "idle");
        commit("setPortfolios", data.data);
      })
      .catch(() => {
        commit("setState", "error");
      });
  },
  leave({ commit, state, rootState }, uuid): void {
    const portfolio = state?.portfolios?.find((p) => p.uuid === uuid);
    if (portfolio) {
      // Optimistically remove right away.
      commit("remove", uuid);

      fetch(
        `${rootState.apiEndpoint}web/v1/groups/${portfolio.uuid}/accept.json`,
        {
          ...rootState.fetchOptions,
          method: "PATCH",
        }
      ).catch(() => {
        // it failed, re add the portfolio
        commit("add", portfolio);
      });
    }
  },
};
