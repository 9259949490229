import {
  IGroupArtistArtwork,
  IGroupArtistArtworks,
} from "@/types/group-artist-artworks";
import { IGroupArtistArtworkMsg } from "@/types/group-artist-artwork-msg";
import { MutationTree } from "vuex";
import { IGroupArtistArtworksState } from "./types";

export const mutations: MutationTree<IGroupArtistArtworksState> = {
  addMsgToArtwork(
    state,
    payload: { groupArtistArtworkId: number; msg: IGroupArtistArtworkMsg }
  ): void {
    // Get the index to replace
    const index = state.groupArtistArtworks.findIndex(
      (g) => g.id === payload.groupArtistArtworkId
    );
    // Make sure we have an index & the existing artwork
    if (index > -1) {
      // Add the msg
      state.groupArtistArtworks[index].group_artist_artwork_recent_msgs.unshift(
        payload.msg
      );
    }
  },
  reset(state): void {
    state.errors = [];
    state.state = "pre-load";
    state.groupArtistArtworks = [];
  },
  setErrors(state, payload: string[]) {
    state.errors = payload;
  },
  setEditingArtworkInfo(state, payload: boolean) {
    state.editingArtworkInfo = payload;
  },
  setGroupArtistArtworks(state, payload: IGroupArtistArtworks) {
    // state.loading = false;
    // state.error = "";
    state.groupArtistArtworks = payload;
  },
  setState(state, payload: IGroupArtistArtworksState["state"]) {
    state.state = payload;
  },
  update(state, payload: IGroupArtistArtwork) {
    state.groupArtistArtworks.unshift(payload);
  },
  addArtwork(state, payload: IGroupArtistArtwork) {
    if (state.state === "empty") {
      state.state = "loaded";
    }
    state.groupArtistArtworks.unshift(payload);
  },
  removeArtwork(state, payload: IGroupArtistArtwork) {
    const index = state.groupArtistArtworks.findIndex(
      (g) => g.id === payload.id
    );
    if (index > -1) {
      state.groupArtistArtworks.splice(index, 1);
    }
    if (state.state === "loaded" && state.groupArtistArtworks.length < 1) {
      state.state = "empty";
    }
  },
  updateArtwork(state, payload: IGroupArtistArtwork) {
    // Get the index to replace
    const index = state.groupArtistArtworks.findIndex(
      (g) => g.id === payload.id
    );
    // Get the existing artwork
    const artwork = state.groupArtistArtworks.find((g) => g.id === payload.id);
    // Make sure we have an index & the existing artwork
    if (index > -1 && artwork) {
      // Replace, but merge existing artwork (new sockets do not come with recent comments)
      state.groupArtistArtworks.splice(index, 1, { ...artwork, ...payload });
    }
  },
  removePriceOrValuation(
    state,
    payload: { artwork_uuid: string; uuid: string }
  ) {
    const artworkIndex = state.groupArtistArtworks.findIndex(
      (a) => a.uuid === payload.artwork_uuid
    );
    const artwork = { ...state.groupArtistArtworks[artworkIndex] };
    const priceIndex = artwork.prices.findIndex((p) => p.uuid === payload.uuid);
    if (priceIndex !== undefined && priceIndex > -1) {
      artwork.prices.splice(priceIndex, 1);
    }
    const valuationIndex = artwork.valuations.findIndex(
      (p) => p.uuid === payload.uuid
    );
    if (valuationIndex !== undefined && valuationIndex > -1) {
      artwork.valuations.splice(valuationIndex, 1);
    }
    state.groupArtistArtworks.splice(artworkIndex, 1, artwork);
  },
};
