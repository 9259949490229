import { IPortfolioArtworkList } from "@/types/portfolio";
import { GetterTree } from "vuex";
import { IRootState } from "../types";
import { IPortfolioState, IPortfolioImportStatus } from "./types";
import type { IPortfolioUploadFields } from "@/types/portfolio-upload-fields";
import {
  IPortfolioMembership,
  IPortfolioMemberships,
} from "@/types/portfolio-memberships";

export const getters: GetterTree<IPortfolioState, IRootState> = {
  name(state): string {
    return state.portfolio ? state.portfolio.name : "…";
  },
  selectedUuids(state): string[] {
    return state.selectedUuids;
  },
  importStatus(state): IPortfolioImportStatus {
    if (state.status && state.status.length > 0)
      return state.status[state.status.length - 1];
    return {
      status: "In queue",
      type: "STARTING",
    };
  },
  // Check to see if the currently signed in user can edit this group
  // This is a weak check for the UI, security checks are on back end
  canEdit(state, _, rootState): boolean {
    if (state.portfolio && state.portfolio["is_meta_portfolio?"]) return true;

    if (state.portfolio && state.portfolio.memberships) {
      const membership = state.portfolio.memberships.find(
        (m: IPortfolioMembership) => {
          // @ts-ignore is module on root state
          return rootState.user &&
            // @ts-ignore is module on root state
            rootState.user.user &&
            // @ts-ignore is module on root state
            m.user.uuid === rootState.user.user.uuid
            ? true
            : false;
        },
      );
      return membership && (membership.role === 0 || membership.role === 1)
        ? true
        : false;
    }
    return false;
  },
  memberships(state): IPortfolioMemberships | null {
    return state.portfolio && state.portfolio.memberships
      ? state.portfolio.memberships
      : null;
  },
  portfolio(state): IPortfolioState["portfolio"] {
    return state.portfolio;
  },
  search(state): string {
    return state.search;
  },
  state(state): IPortfolioState["state"] {
    return state.state;
  },
  report(state): IPortfolioState["report"] {
    return state.report;
  },
  view(state): IPortfolioState["view"] {
    return state.view;
  },
  artworks(state): IPortfolioArtworkList | null {
    if (!state.portfolio) return null;

    return state.portfolio.artwork_list;
  },
  unmatched(state) {
    if (!state.portfolio) return null;
    
    const hash: {[k: string]: {[k: string]: true}} = {
      [state.portfolio.uuid]: {}
    };
    state.portfolio.artwork_list.forEach(artwork => {
      hash[artwork.portfolio.uuid][artwork.uuid] = true;
    });
    
    const unmatched: IPortfolioUploadFields[] = [];
    Object.keys(state.portfolio.portfolio_upload_fields).forEach((portfolioUuid) => {
      if (state.portfolio && hash[portfolioUuid]) {
        Object.keys(state.portfolio.portfolio_upload_fields[portfolioUuid]).forEach(artworkUuid => {
          if (state.portfolio && !hash[portfolioUuid][artworkUuid]) {
            const timestamps = Object.keys(state.portfolio.portfolio_upload_fields[portfolioUuid][artworkUuid]).sort().reverse();
            unmatched.push(state.portfolio.portfolio_upload_fields[portfolioUuid][artworkUuid][timestamps[0]]);
          }
        });
      }
    });
    
    return unmatched;
  },
  uuid(state): string | null {
    return state?.portfolio?.uuid ?? null;
  },
  tags(state): string[] {
    return state.portfolio ? state.portfolio.tags : [];
  },
  defaultCurrency(state): string | null {
    return state.portfolio?.currency || null;
  },
  prices(state): IPortfolioState["prices"] {
    return state.prices;
  },
  isFiltered(state): boolean {
    return state.isFiltered;
  },
  isFocusFiltered(state) {
    return state.isFocusFiltered;
  }
};
