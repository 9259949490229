import { Module } from "vuex";
import { IRootState } from "../types";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { IArtistState } from "./types";

export const state: IArtistState = {
  artist: null,
  errors: [],
  state: "pre-load",
  eventYear: new Date().getFullYear(),
  eventSiginal: "AllSignals",
  eventSorts: [],
  medium: "acquisition_count",
  acquisitionYear: new Date().getFullYear(),
  acquisitionSorts: [],
};

const namespaced: boolean = true;

export const artist: Module<IArtistState, IRootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state,
};
