import router from "@/router";
import {
  IGroupArtistArtwork,
  IGroupArtistArtworks,
} from "@/types/group-artist-artworks";
import { GetterTree } from "vuex";
import { IRootState } from "../types";
import { IGroupArtistArtworksState } from "./types";

export const getters: GetterTree<IGroupArtistArtworksState, IRootState> = {
  count(state): number {
    return state.groupArtistArtworks.length;
  },
  state(state): IGroupArtistArtworksState["state"] {
    return state.state;
  },
  errors(state): string[] {
    return state.errors;
  },
  groupArtistArtworks(state): IGroupArtistArtworks {
    return state.groupArtistArtworks;
  },
  isEditingArtworkInfo(state): boolean {
    return state.editingArtworkInfo;
  },
};
