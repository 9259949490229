import { GetterTree } from "vuex";
import { IRootState } from "../types";
import { IArtistArtworkPricesState } from "./types";
import type { IArtworkPrice } from "@/types/artwork-prices";

export const getters: GetterTree<IArtistArtworkPricesState, IRootState> = {
  count(state): number {
    return state.prices.length;
  },
  state(state): IArtistArtworkPricesState["state"] {
    return state.state;
  },
  errors(state): IArtistArtworkPricesState["errors"] {
    return state.errors;
  },
  mediums(state) {
    return [
      ...new Set(
        state.prices
          // @ts-ignore
          .filter((p) => p.medium && p.medium !== "")
          // @ts-ignore
          .map((p) => p.medium)
      ),
    ].map((m) => {
      return {
        name: m,
      };
    });
  },
  totalPriceCount(state): number {
    return state.prices.length;
  },
  prices:
    (state) =>
    (args: {
      medium: string | null;
      groups: IArtworkPrice["group"][];
      size: "all" | "S" | "M" | "L";
      createdYearRangeStart: number | "";
      createdYearRangeEnd: number | "";
    }): IArtistArtworkPricesState["prices"] => {
      let medium = args.medium;
      if (medium === "Work on Paper") medium = "Works on Paper";
      if (medium === "Print") medium = "Prints & Multiples";

      return state.prices.filter((p: IArtistArtworkPricesState["prices"]) => (
        p.price &&
        (args.medium === null || p.medium === medium) &&
        args.groups.includes(p.group) &&
        (args.size === "all" || args.size === p.size) &&
        (args.createdYearRangeStart === "" || p.created_year >= args.createdYearRangeStart) &&
        (args.createdYearRangeEnd === "" || p.created_year <= args.createdYearRangeEnd)
      ));
    },
};
