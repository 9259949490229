import { Module } from "vuex";
import { IRootState } from "../types";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { IPortfolioSettingsState } from "./types";

export const state: IPortfolioSettingsState = {
  data: {},
  errors: [],
  state: "pre-load",
};

const namespaced: boolean = true;

export const portfolioSettings: Module<IPortfolioSettingsState, IRootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state,
};
