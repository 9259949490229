<script setup lang="ts">
  import { computed, ref, watch } from "vue";
  import type { Ref } from "vue";

  // Define Prop types
  interface Props {
    indexes: [number, number] | [number, number][] | null;
    input: string;
  }
  const props = defineProps<Props>();
  
  interface IPart {
    value: string;
    isHighlighted: boolean;
  }
  
  const parts = computed(() => {
    const p: IPart[] = [];
    
    if (!props.input) return p;
    
    if (!props.indexes) return [{
      value: props.input,
      isHighlighted: false,
    }];
    
    if (typeof props.indexes[0] === "number") {
      if (props.indexes[0] > 0) {
        p.push({
          value: props.input.substr(0, props.indexes[0]),
          isHighlighted: false,
        });
      }
      p.push({
        value: props.input.substr(props.indexes[0], props.indexes[1] as number - props.indexes[0]),
        isHighlighted: true,
      });
      if (props.input.length > (props.indexes[1] as number)) {
        p.push({
          value: props.input.substr(props.indexes[1] as number, props.input.length - 1),
          isHighlighted: false,
        });
      }
    } else {
      // Sort index pairs
      const sortedIndexes = (props.indexes as [number, number][]).toSorted((a, b) => a[0] - b[0]);
      
      // Save current position
      let position = 0;
      
      // Loop through all index pairs
      sortedIndexes.forEach((indexes, i) => {
        if (indexes[0] > position) {
          p.push({
            value: props.input.substr(position, indexes[0] - position),
            isHighlighted: false,
          });
        
          p.push({
            value: props.input.substr(indexes[0], indexes[1] - indexes[0]),
            isHighlighted: true,
          });
          position = indexes[1];
        } else {
          if (indexes[1] > position) {
            p.push({
              value: props.input.substr(position, indexes[1] - position),
              isHighlighted: true,
            });
            position = indexes[1];
          }
        }
        
        if (i === sortedIndexes.length - 1) {
          p.push({
            value: props.input.substr(position, props.input.length - position),
            isHighlighted: false,
          });
        }
      });
    }
    
    return p;
  });
</script>

<template lang="pug">
  span(
    v-for="part in parts"
    :class="part.isHighlighted ? 'bg-slate-300' : ''"
  ) {{ part.value }}
</template>
