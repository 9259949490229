import { MutationTree } from "vuex";
import { IModal, IRootState, IWindowLocation } from "./types";
import * as Sentry from "@sentry/vue";

export const mutations: MutationTree<IRootState> = {
  saveGuard(state, payload: boolean) {
    state.saveGuard = payload;
  },
  addModal(state, payload: string | IModal) {
    if (typeof payload === "string") {
      state.modals.push({ name: payload, args: null });
    } else {
      state.modals.push(payload);
    }
  },
  removeModal(state, payload: string) {
    const index: number = state.modals.findIndex(
      (m: IModal) => m.name === payload
    );
    if (index > -1) {
      state.modals.splice(index, 1);
    }
  },
  closeTopModal(state) {
    state.modals.pop();
  },
  clearModals(state) {
    state.modals = [];
  },
  setEndpoints(state, payload: IWindowLocation) {
    if (payload.hostname === "app.wondeur.ai") {
      state.apiEndpoint = "https://api.wondeur.ai/";
      state.fileEndpoint = "https://cdn.wondeur.ai/";
      state.proxyS3 = true;
    } else if (payload.hostname === "staging.app.wondeur.ai") {
      state.apiEndpoint = "https://staging-api.wondeur.ai/";
      state.fileEndpoint = "https://staging-cdn.wondeur.ai/";
      state.proxyS3 = true;
    } else if (payload.hostname === "localhost") {
      state.apiEndpoint = "http://localhost:8888/";
      state.fileEndpoint = "https://uploads.wondeur.ai/";
      state.proxyS3 = true;
    } else if (
      window.location.hostname.includes("wondereur.com.beta.tailscale.net")
    ) {
      state.apiEndpoint = `http://${window.location.hostname.replace(
        "app",
        "api"
      )}:8888/`;
      state.fileEndpoint = "https://uploads.wondeur.ai/";
      state.proxyS3 = true;
    } else if (window.location.hostname.includes("local")) {
      state.apiEndpoint = `https://${window.location.hostname.replace(
        "app",
        "api"
      )}/`;
      state.fileEndpoint = "https://uploads.wondeur.ai/";
      state.proxyS3 = true;
    } else if (window.location.hostname.includes("wondeur.ai")) {
      state.apiEndpoint = "https://staging-api.wondeur.ai/";
      state.fileEndpoint = "https://staging-cdn.wondeur.ai/";
      state.proxyS3 = true;
    } else if (window.location.hostname.includes("--app-wondeur.netlify.app")) {
      state.apiEndpoint = "https://staging-api.wondeur.ai/";
      state.fileEndpoint = "https://staging-cdn.wondeur.ai/";
      state.proxyS3 = true;
    } else {
      state.apiEndpoint = `https://${window.location.hostname.replace(
        "app",
        "api"
      )}/`;
      state.fileEndpoint = `https://${window.location.hostname.replace(
        "app",
        "cdn"
      )}/`;
    }
  },
  setSentryTraceHeaders(state) {
    const activeSpan = Sentry.getActiveSpan();
    const rootSpan = activeSpan ? Sentry.getRootSpan(activeSpan) : undefined;
    
    // Create `sentry-trace` header
    state.fetchOptions.headers["sentry-trace"] = rootSpan
      ? Sentry.spanToTraceHeader(rootSpan)
      : undefined;
    
    // Create `baggage` header
    state.fetchOptions.headers.baggage = rootSpan
      ? Sentry.spanToBaggageHeader(rootSpan)
      : undefined;
  },
  newContentAvailable(state, payload: boolean) {
    state.newContentAvailable = payload;
  },
};
