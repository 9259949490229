import { MutationTree } from "vuex";
import {
  IGroupedNotification,
  IGroupedNotifications,
  INotificationsState,
} from "./types";

export const mutations: MutationTree<INotificationsState> = {
  clear(state) {
    state.error = false;
    state.groupedNotifications = [];
  },
  setError(state) {
    state.error = true;
  },
  setNotifications(state, payload: IGroupedNotifications) {
    state.groupedNotifications = payload;
  },
  update(state, payload: IGroupedNotification) {
    const index = state.groupedNotifications.findIndex(
      (gn) => gn.date === payload.date
    );
    if (index > -1) {
      const notificationIndex = state.groupedNotifications[
        index
      ].notifications.findIndex((n) => n.id === payload.notifications[0].id);
      if (notificationIndex > -1) {
        state.groupedNotifications[index].notifications.splice(
          notificationIndex,
          1,
          payload.notifications[0]
        );
      } else {
        state.groupedNotifications[index].notifications.unshift(
          payload.notifications[0]
        );
      }
    } else {
      state.groupedNotifications.unshift(payload);
    }
  },
  remove(state, payload: IGroupedNotification): void {
    const index = state.groupedNotifications.findIndex(
      (gn) => gn.date === payload.date
    );
    if (index > -1) {
      const notificationIndex = state.groupedNotifications[
        index
      ].notifications.findIndex((n) => n.id === payload.notifications[0].id);
      if (notificationIndex > -1) {
        state.groupedNotifications[index].notifications.splice(
          notificationIndex,
          1
        );
      }
    }
  },
};
