import { IPortfolioSettings } from "@/types/portfolio-settings";
import { MutationTree } from "vuex";
import { IPortfolioSettingsState } from "./types";

export const mutations: MutationTree<IPortfolioSettingsState> = {
  set(state, payload: IPortfolioSettings) {
    state.data = payload;
    state.state = "idle";
    state.errors = [];
  },
  error(state, error) {
    state.state = "error";
    state.errors = [error];
  },
  clear(state) {
    state.data = {};
    state.errors.splice(0);
    state.state = "pre-load";
  },
  state(state, s) {
    state.state = s;
  },
};
