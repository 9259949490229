import { MutationTree } from "vuex";
import { IMenuState } from "./types";

export const mutations: MutationTree<IMenuState> = {
  toggleFilters(state) {
    state.filtersVisible = state.filtersVisible ? false : true;
    if (state.filtersVisible) {
      state.menuVisible = false;
      state.userMenuVisible = false;
    }
  },
  toggleMenu(state) {
    state.menuVisible = state.menuVisible ? false : true;
    if (state.menuVisible) {
      state.filtersVisible = false;
    }
    if (!state.menuVisible) {
      state.userMenuVisible = false;
    }
  },
  setFiltersVisible(state, visible: boolean) {
    state.filtersVisible = visible;
    if (state.filtersVisible) {
      state.menuVisible = false;
      state.userMenuVisible = false;
    }
  },
  setMenuVisible(state, visible: boolean) {
    state.menuVisible = visible;
    if (state.menuVisible) {
      state.filtersVisible = false;
    }
    if (!state.menuVisible) {
      state.userMenuVisible = false;
    }
  },
  setUserMenuVisible(state, visible: boolean) {
    state.userMenuVisible = visible;
    if (state.menuVisible) {
      state.filtersVisible = false;
    }
  },
  toggleUserMenuVisible(state) {
    state.userMenuVisible = state.userMenuVisible ? false : true;
    if (state.menuVisible) {
      state.filtersVisible = false;
    }
  },
};
