import { ActionTree } from "vuex";
import { IRootState } from "../types";
import { IGroupedNotifications, INotificationsState } from "./types";

export const actions: ActionTree<INotificationsState, IRootState> = {
  fetchData({ commit, rootState }): any {
    fetch(
      `${rootState.apiEndpoint}web/v1/notifications.json`,
      rootState.fetchOptions
    )
      .then((response) => response.json())
      .then((data) => {
        const payload: IGroupedNotifications =
          data.data.grouped_notifications;
        commit("setNotifications", payload);
      })
      .catch((error) => {
        commit("setError");
      });
  },
  markRead({ commit, rootState }, id: number) {
    fetch(
      `${rootState.apiEndpoint}web/v1/notifications/${id}/received.json`,
      {
        ...rootState.fetchOptions,
        method: "POST",
      }
    )
      .then((response) => response.json())
      .then((data) => {
          const payload: IGroupedNotifications =
            data.data.grouped_notifications;
          commit("setNotifications", payload);
        })
        .catch((error) => {
          commit("setError");
        });
  },
};
