import { IInputState } from "./types";

export const state: IInputState = {
  actions: [
    {
      actionLength: 2,
      alises: ["Brief me on"],
      enabled: true,
      filterDataKey: "hits",
      filterEndpoint: "artists/search.json?name=%",
      goToPath: "artists/%",
      id: "artists/brief",
      idSelector: "uuid",
      name: "Research Artist",
      permission: "view_artist",
      triggerAction: "goTo",
    },
  ],
  autoCompleteItems: [],
  input: "",
  isFocused: false,
  selected: [],
};
