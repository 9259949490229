import { GetterTree } from "vuex";
import { IRootState } from "../types";
import { IPortfoliosState } from "./types";

export const getters: GetterTree<IPortfoliosState, IRootState> = {
  portfolios(state): IPortfoliosState["portfolios"] {
    return state.portfolios;
  },
  taggedPortfolios(state): IPortfoliosState["tags"] {
    return state.tags;
  },
  tags(state): string[] {
    return Object.keys(state.tags);
  },
  state(state): IPortfoliosState["state"] {
    return state.state;
  },
};
