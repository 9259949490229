// import Vue from "vue";
import { MutationTree } from "vuex";
import { IPortfoliosState } from "./types";
import { IPortfolioListItem } from "@/types/portfolio";

export const mutations: MutationTree<IPortfoliosState> = {
  add(state, payload: IPortfolioListItem) {
    if (state.portfolios) {
      const tmp = [...state.portfolios];
      const index = tmp.findIndex((g) => g.uuid === payload.uuid);
      if (index > -1) {
        tmp.splice(index, 1, payload);
      } else {
        tmp.push(payload);
      }
      state.portfolios = tmp.sort((a, b) => a.name.localeCompare(b.name));

      if (payload.portfolio_tags) {
        payload.portfolio_tags.forEach((tag) => {
          if (tag in state.tags) {
            state.tags[tag] = [payload, ...state.tags[tag]];
          } else {
            state.tags[tag] = [payload];
          }
        });
      }
    }
  },
  addTag(state, payload: IPortfolioListItem) {
    if (payload.tag && payload.tag in state.tags) {
      state.tags[payload.tag] = [payload, ...state.tags[payload.tag]];
    } else if (payload.tag) {
      state.tags[payload.tag] = [payload];
    }
  },
  removeTag(state, payload: IPortfolioListItem) {
    if (payload.tag && payload.tag in state.tags) {
      state.tags[payload.tag] = state.tags[payload.tag].filter(
        (item) => item.uuid !== payload.uuid
      );
      if (state.tags[payload.tag].length < 1) {
        delete state.tags[payload.tag];
      } else {
        state.tags[payload.tag] = state.tags[payload.tag];
      }
    }
  },
  clear(state) {
    state.state = "pre-load";
    state.portfolios = [];
    state.tags = {};
  },
  setPortfolios(
    state,
    payload: {
      groups: IPortfoliosState["portfolios"];
      tags: IPortfoliosState["tags"];
    }
  ) {
    const tmp = [...payload.groups];
    state.portfolios = tmp.sort((a, b) => a.name.localeCompare(b.name));
    state.tags = payload.tags;
  },
  setState(state, payload: IPortfoliosState["state"]) {
    state.state = payload;
  },
  patch(state, payload: IPortfolioListItem) {
    if (state.portfolios) {
      const tmp = [...state.portfolios];
      // Making sure we're not doing a reference and instead cloning the array
      const index = tmp.findIndex((g) => g.uuid === payload.uuid);
      // Find index of group in list of group names
      if (index > -1) {
        // Then update the name of the group
        tmp.splice(index, 1, payload);
        // Sort groups alphabetically
        state.portfolios = tmp.sort((a, b) => a.name.localeCompare(b.name));
      }

      if (payload.portfolio_tags) {
        payload.portfolio_tags.forEach((tag) => {
          if (tag in state.tags) {
            const index = state.tags[tag].findIndex(
              (p) => p.uuid === payload.uuid
            );
            if (index > -1) {
              state.tags[tag].splice(index, 1, payload);
            } else {
              state.tags[tag] = [payload, ...state.tags[tag]];
            }
          } else {
            state.tags[tag] = [payload];
          }
        });
        Object.keys(state.tags).forEach((tag) => {
          if (!payload.portfolio_tags?.includes(tag)) {
            const index = state.tags[tag].findIndex(
              (p) => p.uuid === payload.uuid
            );
            if (index > -1) {
              state.tags[tag].splice(index, 1);
              if (state.tags[tag].length < 1) {
                delete state.tags[tag];
              }
            }
          }
        });
      }
    }
  },
  remove(state, item: IPortfolioListItem | string) {
    if (typeof item === "object") {
      const index = state?.portfolios?.findIndex((p) => p.uuid === item.uuid);
      if (index > -1) {
        state.portfolios.splice(index, 1);
      }
      if (item.portfolio_tags) {
        item.portfolio_tags.forEach((tag) => {
          if (tag in state.tags) {
            const index = state.tags[tag].findIndex(
              (p) => p.uuid === item.uuid
            );
            if (index > -1) {
              state.tags[tag].splice(index, 1);
            }
          }
        });
      }
    }
  },
};
