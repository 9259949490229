import {
  IGroupArtistArtwork,
  IGroupArtistArtworks,
} from "@/types/group-artist-artworks";
import router from "@/router";
import { ActionTree } from "vuex";
import { IRootState } from "../types";
import { IGroupArtistArtworksState } from "./types";

export const actions: ActionTree<IGroupArtistArtworksState, IRootState> = {
  fetchData: async (
    { commit, rootState },
    ids: {
      group: number;
      groupArtist: number;
    }
  ) => {
    commit("setState", "loading");
    commit("setErrors", []);
    const id = ids?.group || router.currentRoute.value.params.id;
    const artistId =
      ids?.groupArtist || router.currentRoute.value.query.group_artist_id;
    fetch(
      `${rootState.apiEndpoint}web/v1/groups/${id}/group_artists/${artistId}/group_artist_artworks.json`,
      rootState.fetchOptions
    )
      .then((response) => response.json())
      .then((data) => {
        const payload: IGroupArtistArtworks = data.data;
        commit("setGroupArtistArtworks", payload);
        commit("setState", payload.length > 0 ? "loaded" : "empty");
        return true;
      })
      .catch((error) => {
        commit("setErrors", [error.message]);
        commit("setState", "error");
        return false;
      }
    );
  },
  addArtwork: async (
    { commit, rootState },
    params: {
      group_artist_id: number;
      group_artist_artwork: IGroupArtistArtwork;
      group_id: number;
      photo_data: any;
      dataUrl: string;
    }
  ) => {
    return fetch(
      `${rootState.apiEndpoint}web/v1/groups/${params.group_id}` +
      `/group_artists/${params.group_artist_id}` +
      `/group_artist_artworks.json`,
      {
        ...rootState.fetchOptions,
        method: "POST",
        body: JSON.stringify({
            group_artist_artwork: params.group_artist_artwork,
            group_artist_id: params.group_artist_id,
            group_id: params.group_id,
            photo_data: params.photo_data,
          }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        const payload: {
          status: string;
          uuid: string;
        } = data;
        return payload.uuid;
      })
      .catch(() => {
        commit("error");
        return false;
      });
  },
  updateArtwork: async (
    { commit, rootState },
    params: {
      group_id: string;
      group_artist_id: number;
      group_artist_artwork_id: number;
      name: string;
      created_year: number | string;
      medium: string;
      height: string;
      width: string;
      length: string;
      published: boolean;
      photo_data: string;
      dataUrl: string;
    }
  ) => {
    // Optimistic update right away
    commit("updateArtwork", params);

    // Send to API to update
    return fetch(
      `${rootState.apiEndpoint}web/v1/groups/` +
      `${params.group_id}/group_artists/` +
      `${params.group_artist_id}/group_artist_artworks/` +
      `${params.group_artist_artwork_id}.json`,
      {
        ...rootState.fetchOptions,
        method: "PATCH",
        body: JSON.stringify(params),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (!("published" in params) || params.published !== false) {
          const payload: IGroupArtistArtwork = data.data;

          commit("updateArtwork", payload);
        }
        return true;
      })
      .catch((error) => {
        return error.message;
      });
  },
};
