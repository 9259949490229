import { IPermission } from "@/types/permissions";
import { IUser, IUsers } from "@/types/users";
import { GetterTree } from "vuex";
import { IRootState } from "../types";
import { IUserState } from "./types";

export const getters: GetterTree<IUserState, IRootState> = {
  can:
    (state) =>
    (test: keyof IPermission): boolean => {
      if (state.user) {
        if (state.user.permission && state.user.permission[test] !== null) {
          // @ts-ignore ... I ruled out null above ...
          return state.user.permission[test] === true ? true : false;
        }
        if (
          state.user.organization &&
          state.user.organization.permission &&
          state.user.organization.permission[test] !== null
        ) {
          // @ts-ignore ... I ruled out null above ...
          return state.user.organization.permission[test] === true
            ? true
            : false;
        }
      }
      return false;
    },
  name(state): string {
    return state.user && state.user.name ? state.user.name : "";
  },
  id(state): number | string {
    return state.user ? state.user.uuid : "ai";
  },
  isLoaded(state): boolean {
    return state.user ? true : false;
  },
  orgnizationName(state): string {
    return state.user && state.user.organization
      ? state.user.organization.name
      : "";
  },
  members(state): IUsers | null {
    if (
      state.user &&
      state.user.organization &&
      state.user.organization.users
    ) {
      return state.user.organization.users.filter(
        (u) => state.user && u.uuid !== state.user.uuid
      );
    }

    return null;
  },
  stevieEnabled(state): boolean {
    if (state.user) {
      return state.user.stevie_enabled;
    }

    return false;
  },
  user(state): IUser | null {
    return state.user;
  },
  basicUserInfo(state) {
    return {
      name: state?.user?.name,
      photo_urls: state?.user?.photo_urls,
      uuid: state?.user?.uuid,
    };
  },
  defaultCurrency(state): string {
    return state.user?.default_currency || "USD";
  },
};
