import store from "@/store";
import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
  routes: [
    {
      component: () => import("@/views/Home.vue"),
      meta: {
        title: "Home",
      },
      name: "home",
      path: "/",
    },
    
    {
      component: () => import("@/views/StrategicDashboard.vue"),
      meta: {
        title: "Executive Hub",
        permission: "view_dashboard",
      },
      name: "exec-hub",
      path: "/exec-hub",
    },
    {
      component: () => import("@/views/WondeurStrategicDashboard.vue"),
      meta: {
        title: "Wondeur Executive Hub",
        permission: "view_dashboard",
      },
      name: "wondeur-exec-hub",
      path: "/wondeur-exec-hub/:uuid",
    },

    /***********************************************
     *               Artist Pages                  *
     **********************************************/
    {
      meta: {
        permission: "view_artist",
        title: "Artist Analysis",
      },
      path: "/artists/brief",
      redirect: {
        name: "artists-brief",
      },
    },
    {
      component: () => import("@/views/artists/Artist.vue"),
      meta: {
        permission: "view_artist",
        title: "Artist Analysis",
      },
      name: "artists-brief",
      path: "/artists",
    },
    {
      meta: {
        permission: "view_artist",
        title: "Artist Analysis",
      },
      path: "/artists/brief/:id",
      redirect: (to) => {
        const { params } = to;
        return {
          name: "artists-show",
          params: {
            id: params.id,
          },
        };
      },
    },
    {
      component: () => import("@/views/artists/Brief.vue"),
      meta: {
        permission: "view_artist",
        title: "%artist/name% | Artist Analysis",
      },
      name: "artists-show",
      path: "/artists/:id",
    },

    /***********************************************
     *              Portfolios Pages               *
     **********************************************/
    {
      component: () => import("@/views/portfolios/PortfolioNew.vue"),
      meta: {
        permission: "view_portfolio",
        title: "New Portfolio",
      },
      name: "groups-new",
      path: "/portfolios/new",
    },
    {
      component: () => import("@/views/portfolios/PortfolioView.vue"),
      meta: {
        permission: "view_portfolio",
        title: "%portfolio/name% | Portfolio",
      },
      name: "groups-show",
      path: "/portfolios/:id",
    },

    /***********************************************
     *                 User Pages                  *
     **********************************************/
    {
      component: () => import("@/views/user/Logout.vue"),
      meta: {
        entryUrl: false,
        title: "Logout",
      },
      name: "logout",
      path: "/users/sign-out",
    },
    {
      component: () => import("@/views/user/Login.vue"),
      meta: {
        entryUrl: true,
        title: "Login",
      },
      name: "login",
      path: "/users/sign-in",
    },
    {
      component: () => import("@/views/user/TwoFa.vue"),
      meta: {
        entryUrl: true,
        title: "Two FA",
      },
      name: "two-fa",
      path: "/users/two-fa",
    },
    {
      component: () => import("@/views/user/InvitationAccept.vue"),
      meta: {
        entryUrl: true,
        title: "Accept Invitation",
      },
      name: "invitation-accept",
      path: "/users/invitation/accept",
    },
    {
      component: () => import("@/views/user/ForgotPassword.vue"),
      meta: {
        entryUrl: true,
        title: "Forgot Password",
      },
      name: "forgot-password",
      path: "/users/password/new",
    },
    {
      component: () => import("@/views/user/ResetPassword.vue"),
      meta: {
        entryUrl: true,
        title: "Reset Password",
      },
      name: "reset-password",
      path: "/users/password/edit",
    },
    // {
    //   component: () => import("@/views/user/NewUserConfirmation.vue"),
    //   meta: {
    //     entryUrl: true,
    //     title: "New User Confirmation",
    //   },
    //   name: "new-reset-confirmation",
    //   path: "/users/confirmation/new",
    // },
    {
      component: () => import("@/views/user/UserConfirmation.vue"),
      meta: {
        entryUrl: true,
        title: "User Confirmation",
      },
      name: "reset-confirmation",
      path: "/users/confirmation",
    },
    {
      component: () => import("@/views/user/Profile.vue"),
      meta: {
        entryUrl: false,
        title: "My Profile",
      },
      name: "profile",
      path: "/user",
    },
    {
      component: () => import("@/views/user/EnableTwoFactor.vue"),
      meta: {
        entryUrl: false,
        title: "Set Up Two Factor Auth",
      },
      name: "enable-two-factor",
      path: "/user/enable-two-factor",
    },
    {
      component: () => import("@/views/user/VerifyInstallation.vue"),
      meta: {
        entryUrl: false,
        title: "Verify Installation",
      },
      name: "verify-installation",
      path: "/user/verify-installation",
    },

    /***********************************************
     *                Can not Page                 *
     **********************************************/
    {
      component: () => import("@/views/errors/CanNot.vue"),
      meta: {
        entryUrl: false,
        title: "Request Access",
      },
      name: "can-not",
      path: "/request",
    },

    /***********************************************
     *                  404 Page                   *
     **********************************************/
    {
      component: () => import("./views/errors/ErrorNotFound.vue"),
      meta: {
        entryUrl: false,
        title: "Page Not Found (404)",
      },
      name: "error-not-found",
      path: "/**",
    },
  ],
});

router.beforeEach(async (to: any, from: any, next: any) => {
  if (
    store.state.saveGuard &&
    !confirm(
      "Are you sure you want to leave? You will lose your unsaved changes!",
    )
  ) {
    next(false);
  } else {
    store.commit("saveGuard", false);

    // If there are any modals open lets close them
    if (store.state.modals.length > 0) {
      store.commit("clearModals");
    }

    // @ts-ignore
    if (store.state.menu.menuVisible) {
      // Make sure to hide the menu before navigation
      store.commit("menu/setMenuVisible", false);
    }
    // @ts-ignore
    if (store.state.menu.filtersVisible) {
      // Make sure to hide the menu before navigation
      store.commit("menu/setFiltersVisible", false);
    }

    // If we do not have the endpoints set lets do that first
    if (!store.state.apiEndpoint) {
      await store.commit("setEndpoints", window.location);
    }

    // Make sure we are online first
    if (navigator.onLine) {
      // If we have the user already lets just go there
      if (store.getters["user/user"]) {
        checkPermission(to, from, next);
      } else {
        // If not lets check to see if the user is logged in and get their data
        const isLoggedIn = await store.dispatch("user/fetchData", to);

        if (isLoggedIn === true) {
          // If we are logged in lets check permissions
          checkPermission(to, from, next);
        } else if ("entryUrl" in to.meta && to.meta.entryUrl === true) {
          // If we are not logged in and it is an entry url lets go there
          next();
        } else {
          // Otherwise stop and go to login page
          next(false);
          router.push({
            name: "login",
            query: {
              // Make sure we do not store entry urls as a redirect path
              redirect: to.path.includes("user") ? "/" : to.path,
            },
          });
        }
      }
    } else {
      next(false);
    }
  }
});

const checkPermission = (to: any, from: any, next: any) => {
  if ("entryUrl" in to.meta && to.meta.entryUrl === true) {
    router.push("/");
  } else if (
    !to.meta ||
    !to.meta.permission ||
    store.getters["user/can"](to.meta.permission)
  ) {
    next();
  } else {
    next(false);
    router.push({
      name: "can-not",
      query: {
        back: to.path,
      },
    });
  }
};

export default router;
