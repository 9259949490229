// import * as Sentry from "@sentry/browser";
import store from "@/store/index";
import { MutationTree } from "vuex";
import { IUserState } from "./types";
import * as Sentry from "@sentry/vue";

export const mutations: MutationTree<IUserState> = {
  setLoading(state, payload: boolean) {
    state.loading = payload;
  },
  userLoaded(state, payload: IUserState["user"]) {
    state.loading = false;
    state.error = false;
    state.user = payload;
    if (state.user) {
      Sentry.setUser({
        email: state.user.email ? state.user.email : undefined,
        id: state.user.uuid ? String(state.user.uuid) : undefined,
        username: state.user.name ? state.user.name : undefined,
      });
    }
  },
  userError(state) {
    state.loading = false;
    state.error = true;
    state.user = null;
  },
  setSaving(state, payload: boolean) {
    state.saving = payload;
  },
  clear(state) {
    state.loading = false;
    state.error = false;
    state.user = null;
    state.saving = false;
    if (window.localStorage) {
      window.localStorage.clear();
    }
    store.commit("notifications/clear");
    store.commit("portfolios/clear");
    store.commit("portfolio/clear");
    store.commit("clearModals");
  },
};
