import { MutationTree } from "vuex";
import { IArtistArtworkPricesState } from "./types";

export const mutations: MutationTree<IArtistArtworkPricesState> = {
  state(state, payload: IArtistArtworkPricesState["state"]) {
    state.state = payload;
  },
  errors(state, payload: IArtistArtworkPricesState["errors"]) {
    state.errors = payload;
  },
  prices(state, payload: IArtistArtworkPricesState["prices"]) {
    state.prices = payload;
  },
  reset(state) {
    state.prices = [];
    state.errors = [];
    state.state = "pre-load";
  },
};
