import { Module } from "vuex";
import { IRootState } from "../types";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { INotificationsState } from "./types";

export const state: INotificationsState = {
  error: false,
  groupedNotifications: [],
};

const namespaced: boolean = true;

export const notifications: Module<INotificationsState, IRootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state,
};
