import { IArtist } from "@/types/artists";
import { IAcquisition } from "@/types/acquisitions";
import { IEvent } from "@/types/events";
import { GetterTree } from "vuex";
import { IRootState } from "../types";
import {
  IArtistState,
  IArtistScoreMediumCountKey,
  IArtistScoreEventAcclaimKey,
} from "./types";

export const getters: GetterTree<IArtistState, IRootState> = {
  artist(state): IArtist | null {
    return state.artist;
  },
  sentence: (state) => (args: { name: string; type: string }) => {
    return state.artist?.sentences.find(
      (s) => s.name === args.name && s.type === args.type
    );
  },
  eventYears(state): number[] {
    if (!state.artist) return [];

    const allYears: number[] = Object.keys(state.artist.events).map((y) =>
      Number(y)
    );
    const firstYear: number = allYears[0];
    const lastYear: number = allYears[allYears.length - 1];
    const years: number[] = [];
    for (let year: number = firstYear; year <= lastYear; year++) {
      years.push(year);
    }
    return years;
  },
  events(state) {
    if (
      !state.artist ||
      !state.artist.events ||
      !state.artist.events[state.eventYear]
    )
      return [];

    let events = state.artist?.events[state.eventYear]?.filter(
      (e) =>
        state.eventSiginal === "AllSignals" ||
        state.eventSiginal === "AllEvents" ||
        e.type === state.eventSiginal
    );

    state.eventSorts.forEach((sort) => {
      events = events.sort((a: IEvent, b: IEvent) => {
        if (sort.type === "number") {
          // @ts-ignore
          if (sort.direction === "asc") return a[sort.key] - b[sort.key];

          // @ts-ignore
          return b[sort.key] - a[sort.key];
        }

        if (sort.type === "string") {
          // Fix sorting of accented letters and mix of upper and lowercase
          // https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript#answer-37511463
          // Also make sure null strings are set to empty strings
          let aNormalized = "";
          if (typeof a[sort.key] === "string") {
            aNormalized = a[sort.key]
              // @ts-ignore
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "");
          }

          let bNormalized = "";
          if (typeof b[sort.key] === "string") {
            bNormalized = b[sort.key]
              // @ts-ignore
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "");
          }

          if (sort.direction === "asc")
            return aNormalized < bNormalized ? 1 : -1;

          return aNormalized > bNormalized ? 1 : -1;
        }

        // This is for sort.type === "boolean" (last type for now, fallback)
        if (sort.direction === "asc")
          // @ts-ignore
          return a[sort.key] > b[sort.key] ? 1 : -1;

        // @ts-ignore
        return a[sort.key] < b[sort.key] ? 1 : -1;
      });
    });

    return events;
  },
  eventArtistScoreProperty(state): IArtistScoreEventAcclaimKey {
    switch (state.eventSiginal) {
      case "GroupShow":
        return "group_show_acclaim";
      case "SoloShow":
        return "solo_show_acclaim";
      case "AllEvents":
        return "show_acclaim";
      default:
        return "score";
    }
  },
  eventChartData(state, g) {
    const property: IArtistScoreEventAcclaimKey = g.eventArtistScoreProperty;
    return state.artist?.artist_scores.map((s) => s[property]);
  },
  acquisitionsChartData(state) {
    return state.artist?.artist_scores.map((s) => s[state.medium]);
  },
  chartLabels(state): number[] {
    return state.artist?.artist_scores.map((s) => s.year) || [];
  },
  eventYear(state): number {
    return state.eventYear;
  },
  eventSiginal(state) {
    return state.eventSiginal;
  },
  id(state): number | null {
    return state.artist ? state.artist.id : null;
  },
  name(state): string {
    return state.artist ? state.artist.name : "…";
  },
  downloadPdfUrl(state, _, rootState): string | null {
    if (!state.artist) {
      return null;
    }
    return `${rootState.apiEndpoint}web/v1/artists/${state.artist.id}/download-reports.pdf`;
  },
  state(state) {
    return state.state;
  },
  errors(state) {
    return state.errors;
  },
  eventSorts(state): IArtistState["eventSorts"] {
    return state.eventSorts;
  },
  mediumCount: (state) => (key: IArtistScoreMediumCountKey) => {
    if (!state.artist) return 0;

    return state.artist.artist_scores.reduce((a, c) => a + c[key], 0);
  },
  mediums(state, g) {
    if (!state.artist) return null;

    return [
      {
        key: "acquisition_count",
        name: "All Mediums",
        count: g.mediumCount("acquisition_count"),
      },
      {
        key: "painting_acquisition_count",
        name: "Painting",
        count: g.mediumCount("painting_acquisition_count"),
      },
      {
        key: "mixed_media_acquisition_count",
        name: "Mixed Media",
        count: g.mediumCount("mixed_media_acquisition_count"),
      },
      {
        key: "sculpture_acquisition_count",
        name: "Sculpture",
        count: g.mediumCount("sculpture_acquisition_count"),
      },
      {
        key: "photography_acquisition_count",
        name: "Photography",
        count: g.mediumCount("photography_acquisition_count"),
      },
      {
        key: "prints_and_multiples_acquisition_count",
        name: "Prints & Mutiples",
        count: g.mediumCount("prints_and_multiples_acquisition_count"),
      },
      {
        key: "works_on_paper_acquisition_count",
        name: "Works on Paper",
        count: g.mediumCount("works_on_paper_acquisition_count"),
      },
    ].filter((m) => m.count > 0);
  },
  medium(state) {
    return state.medium;
  },
  mediumName(state) {
    switch (state.medium) {
      case "painting_acquisition_count":
        return "Painting";
      case "mixed_media_acquisition_count":
        return "Mixed Media";
      case "sculpture_acquisition_count":
        return "Sculpture";
      case "photography_acquisition_count":
        return "Photography";
      case "prints_and_multiples_acquisition_count":
        return "Prints & Multiples";
      case "works_on_paper_acquisition_count":
        return "Works on Paper";
      default:
        return "All Mediums";
    }
  },
  acquisitionYear(state) {
    return state.acquisitionYear;
  },
  acquisitionYears(state): number[] {
    if (!state.artist) return [];

    const allYears: number[] = Object.keys(state.artist.acquisitions).map((y) =>
      Number(y)
    );
    const firstYear: number = allYears[0];
    const lastYear: number = allYears[allYears.length - 1];
    const years: number[] = [];
    for (let year: number = firstYear; year <= lastYear; year++) {
      years.push(year);
    }
    return years;
  },
  acquisitions(state, g) {
    if (
      !state.artist ||
      !state.artist.acquisitions ||
      !state.artist.acquisitions[state.acquisitionYear]
    )
      return [];

    let acqusitions = state.artist?.acquisitions[state.acquisitionYear]?.filter(
      (a) => g.mediumName === "All Mediums" || a.artwork_medium === g.mediumName
    );

    state.acquisitionSorts.forEach((sort) => {
      acqusitions = acqusitions.sort((a: IAcquisition, b: IAcquisition) => {
        if (sort.type === "number") {
          // @ts-ignore
          if (sort.direction === "asc") return a[sort.key] - b[sort.key];

          // @ts-ignore
          return b[sort.key] - a[sort.key];
        }

        if (sort.type === "string") {
          // Fix sorting of accented letters and mix of upper and lowercase
          // https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript#answer-37511463
          // Also make sure null strings are set to empty strings
          let aNormalized = "";
          if (typeof a[sort.key] === "string") {
            // @ts-ignore type check above should avoid null
            aNormalized = a[sort.key]
              // @ts-ignore
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "");
          }

          let bNormalized = "";
          if (typeof b[sort.key] === "string") {
            // @ts-ignore type check above should avoid null
            bNormalized = b[sort.key]
              // @ts-ignore
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "");
          }

          if (sort.direction === "asc")
            return aNormalized < bNormalized ? 1 : -1;

          return aNormalized > bNormalized ? 1 : -1;
        }

        // This is for sort.type === "boolean" (last type for now, fallback)
        if (sort.direction === "asc")
          // @ts-ignore
          return a[sort.key] > b[sort.key] ? 1 : -1;

        // @ts-ignore
        return a[sort.key] < b[sort.key] ? 1 : -1;
      });
    });

    return acqusitions;
  },
  acquisitionSorts(state) {
    return state.acquisitionSorts;
  },
};
