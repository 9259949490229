import { IUser } from "@/types/users";
import { ActionTree } from "vuex";
import { IRootState } from "../types";
import { IUserState } from "./types";

export const actions: ActionTree<IUserState, IRootState> = {
  fetchData: async ({ commit, rootState }, to: any) => {
    commit("setLoading", true);
    return fetch(
      `${rootState.apiEndpoint}web/v1/user-info.json`,
      rootState.fetchOptions
    )
      .then((response) => response.json())
      .then((data) => {
        const payload: IUser = data.data.user;
        commit("userLoaded", payload);
        return true;
      })
      .catch((error) => {
        commit("userError", error);
        return false;
      });
  },
  updateUser(
    { commit, rootState },
    params: {
      photo_data: string;
      dataUrl: string;
      name: string;
      title: string;
      bio: string;
    }
  ): void {
    commit("setSaving", true);
    fetch(
      `${rootState.apiEndpoint}web/v1/user-info.json`,
      {
        ...rootState.fetchOptions,
        method: "PATCH",
        body: JSON.stringify({ user: params }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        const payload: IUser = data.data.user;
        commit("userLoaded", payload);
        commit("setSaving", false);
      })
      .catch(() => {
        commit("error");
        commit("setSaving", false);
      });
  },
  logout: async ({ commit, rootState }) => {
    return fetch(
      `${rootState.apiEndpoint}users/sign_out.json`,
      {
        ...rootState.fetchOptions,
        method: "DELETE",
      }
    )
      .then(() => {
        commit("clear");
        return true;
      })
      .catch(() => {
        return false;
      });
  },
};
