export interface IEditionFormatterParams {
  number: number | null;
  total: number | null;
}

export function editionFormatter(input: IEditionFormatterParams): string {
  if (!input.number && !input.total) return "";
  
  if (!input.number) return `${input.total}`;
  
  if (!input.total) return `# ${input.number} of unknowen`;
  
  return `${input.number} of ${input.total}`;
}