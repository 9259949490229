import { Module } from "vuex";
import { IRootState } from "../types";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { IGroupArtistArtworksState } from "./types";

export const state: IGroupArtistArtworksState = {
  editingArtworkInfo: false,
  errors: [],
  groupArtistArtworks: [],
  state: "pre-load",
};

const namespaced: boolean = true;

export const groupArtistArtworks: Module<
  IGroupArtistArtworksState,
  IRootState
> = {
  actions,
  getters,
  mutations,
  namespaced,
  state,
};
