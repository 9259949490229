import type { IPortfolioSettings } from '@/types/portfolio-settings.ts';
import { ActionTree } from "vuex";
import { IRootState } from "../types";
import { IPortfolioSettingsState } from "./types";

export const actions: ActionTree<IPortfolioSettingsState, IRootState> = {
  fetch({ commit, rootState }, uuid: string) {
    commit("clear");
    commit("state", "loading");
    fetch(
      `${rootState.apiEndpoint}web/v1/portfolio/settings/${uuid}.json`,
      rootState.fetchOptions
    )
      .then((response) => response.json())
      .then((data) => {
        const payload: IPortfolioSettings = data.data.portfolio_settings;
        commit("set", payload);
      })
      .catch((error) => {
        commit("error", error);
      });
  },
  save: async (
      {commit, rootState},
      args: {
          uuid: string,
          settings: IPortfolioSettings,
      },
  ) => {
      await fetch(
        `${rootState.apiEndpoint}web/v1/portfolio/settings/${args.uuid}.json`,
        {
            ...rootState.fetchOptions,
            method: 'PATCH',
            body: JSON.stringify({ portfolio_settings: args.settings }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          const payload: IPortfolioSettings = data.data.portfolio_settings;
          commit("set", payload);
          return true;
        })
        .catch((error) => {
          commit("error", error);
          return false;
        });
  },
};
