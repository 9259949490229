import { Module } from "vuex";
import { IRootState } from "../types";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { IArtistArtworkPricesState } from "./types";

export const state: IArtistArtworkPricesState = {
  prices: [],
  errors: [],
  state: "pre-load",
};

const namespaced: boolean = true;

export const artistArtworkPrices: Module<
  IArtistArtworkPricesState,
  IRootState
> = {
  actions,
  getters,
  mutations,
  namespaced,
  state,
};
