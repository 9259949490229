import { Module } from "vuex";
import { IRootState } from "../types";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { state } from "./state";
import { IInputState } from "./types";

const namespaced: boolean = true;

export const input: Module<IInputState, IRootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state,
};
