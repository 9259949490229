import { Module } from "vuex";
import { IRootState } from "../types";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { IPortfoliosState } from "./types";

export const state: IPortfoliosState = {
  state: "pre-load",
  portfolios: [],
  tags: {},
};

const namespaced: boolean = true;

export const portfolios: Module<IPortfoliosState, IRootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state,
};
