import { IArtist } from "@/types/artists";
import { ActionTree } from "vuex";
import { IRootState } from "../types";
import { IArtistState } from "./types";

export const actions: ActionTree<IArtistState, IRootState> = {
  fetchData({ commit, rootState }, id: number): any {
    commit("clear");
    commit("state", "loading");
    fetch(
      `${rootState.apiEndpoint}web/v1/artists/${id}.json`,
      rootState.fetchOptions
    )
      .then((response) => response.json())
      .then((data) => {
        const payload: IArtist = data.data;
        commit("set", payload);
      })
      .catch((error) => {
        commit("error", error);
      });
  },
};
