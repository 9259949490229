import router from "@/router";
import { ActionTree } from "vuex";
import { IAction, IInputState } from "./types";

import { IRootState } from "../types";

export const actions: ActionTree<IInputState, IRootState> = {
  goTo({ commit, state }, action: IAction): any {
    let goToPath: string = action.goToPath;
    const idSelector: string = action.idSelector || "id";

    if (state.selected.length > 1) {
      goToPath = goToPath.replace("%", state.selected[1][idSelector]);

      if (state.selected.length > 2) {
        goToPath = goToPath.replace("%", state.selected[2][idSelector]);
      }
    }

    commit("clearSelected");
    router.push(`/${goToPath}`);
  },
};
