import { IArtist } from "@/types/artists";
import { MutationTree } from "vuex";
import {
  IArtistState,
  IArtistEventSortKey,
  IArtistAcquisitionsSortKey,
} from "./types";

export const mutations: MutationTree<IArtistState> = {
  set(state, payload: IArtist) {
    state.artist = payload;
    state.state = "idle";
    state.errors = [];
  },
  error(state, error) {
    state.state = "error";
    state.errors = [error];
  },
  clear(state) {
    state.artist = null;
    state.errors.splice(0);
    state.state = "pre-load";
    state.eventSorts.splice(0);
    state.eventYear = new Date().getFullYear();
    state.acquisitionSorts.splice(0);
    state.acquisitionYear = new Date().getFullYear();
  },
  state(state, s) {
    state.state = s;
  },
  eventYear(state, year) {
    state.eventYear = year;
  },
  eventSiginal(state, siginal) {
    state.eventSiginal = siginal;
  },
  medium(state, medium) {
    state.medium = medium;
  },
  eventSorts(
    state,
    sorts: { key: IArtistEventSortKey; type: "string" | "number" | "boolean" }[]
  ) {
    sorts.forEach((sort, i) => {
      const key = sort.key;
      const type = sort.type;
      const index = state.eventSorts.findIndex((s) => s.key === key);
      const indexCheck = state.eventSorts.length - sorts.length + i;
      // Check to see if it is already a sort and the last item(s) in the sort
      // if so reverse the direction
      if (index > -1 && index === indexCheck) {
        const direction =
          state.eventSorts[index].direction === "asc" ? "desc" : "asc";
        state.eventSorts.splice(index, 1, { key, direction, type });
      }
      // if it is in the sort but not the last remove it
      if (index > -1 && index !== indexCheck) state.eventSorts.splice(index, 1);
      // if it is in the sort but not the last or not in the sort add it as descending
      if (index < 0 || index !== indexCheck)
        state.eventSorts.push({ key, direction: "desc", type });
    });
  },
  acquisitionYear(state, year) {
    state.acquisitionYear = year;
  },
  acquisitionSorts(
    state,
    sorts: {
      key: IArtistAcquisitionsSortKey;
      type: "string" | "number" | "boolean";
    }[]
  ) {
    sorts.forEach((sort, i) => {
      const key = sort.key;
      const type = sort.type;
      const index = state.acquisitionSorts.findIndex((s) => s.key === key);
      const indexCheck = state.acquisitionSorts.length - sorts.length + i;
      // Check to see if it is already a sort and the last item(s) in the sort
      // if so reverse the direction
      if (index > -1 && index === indexCheck) {
        const direction =
          state.acquisitionSorts[index].direction === "asc" ? "desc" : "asc";
        state.acquisitionSorts.splice(index, 1, { key, direction, type });
      }
      // if it is in the sort but not the last remove it
      if (index > -1 && index !== indexCheck)
        state.acquisitionSorts.splice(index, 1);
      // if it is in the sort but not the last or not in the sort add it as descending
      if (index < 0 || index !== indexCheck)
        state.acquisitionSorts.push({ key, direction: "desc", type });
    });
  },
};
