import { IPortfolioSettings } from "@/types/portfolio-settings";
import { GetterTree } from "vuex";
import { IRootState } from "../types";
import { IPortfolioSettingsState } from "./types";

export const getters: GetterTree<IPortfolioSettingsState, IRootState> = {
  data(state): IPortfolioSettings {
    return state.data;
  },
  state(state) {
    return state.state;
  },
  errors(state) {
    return state.errors;
  },
};
