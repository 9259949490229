import { MutationTree } from "vuex";
import { IAutoCompleteItems, IInputState } from "./types";
import { IArtist } from "@/types/artists";

export const mutations: MutationTree<IInputState> = {
  setIsFocused(state, isFocused: boolean) {
    state.isFocused = isFocused;
  },
  setInput(state, input: string) {
    state.input = input;
  },
  select(state, item: IArtist) {
    if (item) {
      state.input = "";
      state.selected.push(item);
      state.autoCompleteItems = [];
    }
  },
  popSelected(state) {
    state.selected.pop();
  },
  clearSelected(state) {
    state.selected = [];
    state.autoCompleteItems = [];
  },
  clearAutoCompleteItems(state) {
    state.autoCompleteItems = [];
  },
  setAutoCompleteItems(state, payload: IAutoCompleteItems) {
    state.autoCompleteItems = payload;
  },
  autoCompleteItemsError(state) {
    state.autoCompleteItems = [
      {
        icon: null,
        id: null,
        name: "Oops there was an error ...",
        score: null,
      },
    ];
  },
  selectDefault(state) {
    state.selected.push(
      state.actions.filter((a) => a.id === "artists/brief")[0],
    );
  },
};
